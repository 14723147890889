import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, deleteUser } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { FiAlertTriangle } from 'react-icons/fi';
import { secureConsole } from '../../utils/security';

interface UserData {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  range: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  [key: string]: any;
}

const DeleteUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [confirmText, setConfirmText] = useState('');

  useEffect(() => {
    if (id) {
      fetchUserData();
    }
  }, [id]);

  const fetchUserData = async () => {
    try {
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, 'users', id!));
      
      if (userDoc.exists()) {
        setUserData(userDoc.data() as UserData);
      } else {
        toast.error('User not found');
        navigate('/users');
      }
    } catch (error) {
      secureConsole.error('Error fetching user:', error);
      toast.error('Failed to fetch user data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!userData || confirmText !== userData.email) {
      toast.error('Please confirm deletion by typing the email address');
      return;
    }

    setIsDeleting(true);

    try {
      const db = getFirestore();
      const auth = getAuth();

      // Delete from Firestore
      await deleteDoc(doc(db, 'users', id!));

      // If user has Firebase Auth account, delete it too
      if (userData.uid) {
        const user = auth.currentUser;
        if (user && user.uid === userData.uid) {
          await deleteUser(user);
        }
      }

      toast.success('User deleted successfully');
      navigate('/users');
    } catch (error) {
      secureConsole.error('Error deleting user:', error);
      toast.error('Failed to delete user');
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading || !userData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl font-semibold mb-6">Delete User</h1>
        
        <div className="bg-dark-secondary/30 rounded-xl p-6 space-y-6">
          <div className="flex items-center justify-center p-4 bg-red-500/10 rounded-lg">
            <FiAlertTriangle className="text-red-500 w-12 h-12" />
          </div>

          <div className="text-center">
            <h2 className="text-xl font-medium text-red-500">Delete User Account</h2>
            <p className="mt-2 text-gray-400">
              Are you sure you want to delete the account for{' '}
              <span className="font-medium text-white">
                {userData.firstName} {userData.lastName}
              </span>
              ? This action cannot be undone.
            </p>
          </div>

          <div className="border-t border-white/10 pt-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">
                  Type <span className="font-mono">{userData.email}</span> to confirm
                </label>
                <input
                  type="text"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg bg-dark-secondary/50 border border-white/10 focus:border-red-500 focus:ring-1 focus:ring-red-500"
                  placeholder="Enter email to confirm"
                />
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => navigate('/users')}
                  className="px-4 py-2 rounded-lg bg-gray-500/20 text-gray-300 hover:bg-gray-500/30 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  disabled={confirmText !== userData.email || isDeleting}
                  className="px-4 py-2 rounded-lg bg-red-500/20 text-red-500 hover:bg-red-500/30 transition-colors duration-200 disabled:opacity-50"
                >
                  {isDeleting ? 'Deleting...' : 'Delete User'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
