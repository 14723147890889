import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Function to check if user is admin
export const isAdmin = async (uid: string): Promise<boolean> => {
  try {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) return false;

    // Try to find user by authUid first
    const usersRef = collection(db, 'userDetails');
    const authQuery = query(usersRef, where('authUid', '==', uid));
    const authSnapshot = await getDocs(authQuery);

    if (!authSnapshot.empty) {
      const userData = authSnapshot.docs[0].data();
      return userData.userRole === 'admin';
    }

    // If not found by authUid, try by email
    if (user.email) {
      const emailQuery = query(usersRef, where('email', '==', user.email.toLowerCase()));
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        const userData = emailSnapshot.docs[0].data();
        return userData.userRole === 'admin';
      }
    }

    return false;
  } catch (error) {
    secureConsole.error('Error checking admin status:', error);
    return false;
  }
};

// Function to verify authentication and admin status
export const verifyAdminAccess = async (): Promise<boolean> => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    return false;
  }

  return await isAdmin(user.uid);
};

// Function to verify basic authentication
export const verifyAuth = async (): Promise<boolean> => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    return false;
  }

  try {
    const db = getFirestore();
    const userRef = doc(db, 'userDetails', user.uid);
    const userDoc = await getDoc(userRef);
    return userDoc.exists();
  } catch (error) {
    secureConsole.error('Error verifying auth:', error);
    return false;
  }
};

// Function to sanitize data before logging
export const sanitizeDataForLogs = (data: any): any => {
  if (!data) return data;
  
  const sensitiveFields = [
    'password',
    'email',
    'phone',
    'address',
    'creditCard',
    'ssn',
    'token',
    'secret',
    'key',
    'aadhaarNumber',
    'witness1Aadhaar',
    'witness2Aadhaar',
    'currentAddressLine1',
    'currentAddressLine2',
    'permanentAddressLine1',
    'permanentAddressLine2',
    'currentPinCode',
    'permanentPinCode',
    'photo',
    'aadhaarFront',
    'aadhaarBack',
    'promissoryNote',
    'signedForm',
    'authUid'
  ];

  if (typeof data === 'object') {
    const sanitized = { ...data };
    for (const field of sensitiveFields) {
      if (field in sanitized) {
        sanitized[field] = '[REDACTED]';
      }
    }
    
    // Recursively sanitize nested objects
    Object.keys(sanitized).forEach(key => {
      if (typeof sanitized[key] === 'object' && sanitized[key] !== null) {
        sanitized[key] = sanitizeDataForLogs(sanitized[key]);
      }
    });
    
    return sanitized;
  }
  
  // If the data is a string, check if it looks like sensitive data
  if (typeof data === 'string') {
    const sensitivePatterns = [
      /^\d{12}$/, // Aadhaar number pattern
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, // Email pattern
      /^\d{10}$/, // Phone number pattern
      /^\d{6}$/, // PIN code pattern
    ];
    
    if (sensitivePatterns.some(pattern => pattern.test(data))) {
      return '[REDACTED]';
    }
  }
  
  return data;
};

// Custom console wrapper to prevent sensitive data logging
export const secureConsole = {
  log: (message?: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV === 'production') return;
    console.log(sanitizeDataForLogs(message), ...optionalParams.map(sanitizeDataForLogs));
  },
  error: (message?: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV === 'production') {
      // In production, you might want to log to a secure logging service instead
      return;
    }
    console.error(sanitizeDataForLogs(message), ...optionalParams.map(sanitizeDataForLogs));
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV === 'production') return;
    console.warn(sanitizeDataForLogs(message), ...optionalParams.map(sanitizeDataForLogs));
  }
};

// Higher-order component for route protection
export const withAdminProtection = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  return function WithAdminProtectionWrapper(props: P) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
      const checkAuth = async () => {
        try {
          const auth = getAuth();
          const user = auth.currentUser;

          if (!user) {
            navigate('/login');
            return;
          }

          // Check if user is admin
          const isUserAdmin = await isAdmin(user.uid);
          
          if (!isUserAdmin) {
            secureConsole.log('Access denied: User is not an admin');
            navigate('/login');
            return;
          }

          setIsAuthorized(true);
        } catch (error) {
          secureConsole.error('Auth check error:', error);
          setIsAuthorized(false);
          navigate('/login');
        } finally {
          setLoading(false);
        }
      };

      checkAuth();
    }, [navigate]);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (!isAuthorized) {
      return <div>Access denied. Only administrators can access this page.</div>;
    }

    return <WrappedComponent {...props} />;
  };
};
