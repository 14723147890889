import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firebaseAuth } from '../../config/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import logo from '../../images/logo.png';
import { secureConsole } from '../../utils/security';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check if user has admin role
        const db = getFirestore();
        const usersRef = collection(db, 'userDetails');
        const q = query(usersRef, where('authUid', '==', user.uid));
        
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            if (userData.userRole === 'admin') {
              navigate('/admin/dashboard');
            } else {
              // If not admin, sign out and show error
              await firebaseAuth.signOut(auth);
              toast.error('Access denied. Only administrators can access this dashboard.');
              setError('This dashboard is for administrators only.');
            }
          } else {
            // If no user document found, sign out
            await firebaseAuth.signOut(auth);
            toast.error('User profile not found');
            setError('User profile not found in the system.');
          }
        } catch (error) {
          secureConsole.error('Error checking admin status:', error);
          await firebaseAuth.signOut(auth);
          toast.error('Error verifying admin access');
          setError('Failed to verify admin access.');
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      setLoading(true);
      // First, sign in with email/password
      const userCredential = await firebaseAuth.signInWithEmailAndPassword(auth, email, password);
      
      // Check if user has admin role
      const db = getFirestore();
      const usersRef = collection(db, 'userDetails');
      
      // Try finding user by both authUid and email
      const userQuery = query(usersRef, 
        where('email', '==', email.toLowerCase())
      );
      
      const querySnapshot = await getDocs(userQuery);
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        secureConsole.log('User data found:', { email: userData.email, role: userData.userRole });
        
        if (userData.userRole === 'admin') {
          navigate('/admin/dashboard', { replace: true });
        } else {
          // If not admin, sign out immediately
          await firebaseAuth.signOut(auth);
          toast.error('Access denied. Only administrators can access this dashboard.');
          setError('This dashboard is for administrators only.');
        }
      } else {
        // If no user document found, try finding by authUid
        const authQuery = query(usersRef, where('authUid', '==', userCredential.user.uid));
        const authSnapshot = await getDocs(authQuery);
        
        if (authSnapshot.empty) {
          await firebaseAuth.signOut(auth);
          toast.error('User profile not found');
          setError('User profile not found in the system.');
        } else {
          const userData = authSnapshot.docs[0].data();
          secureConsole.log('User data found by authUid:', { email: userData.email, role: userData.userRole });
          
          if (userData.userRole === 'admin') {
            navigate('/admin/dashboard', { replace: true });
          } else {
            await firebaseAuth.signOut(auth);
            toast.error('Access denied. Only administrators can access this dashboard.');
            setError('This dashboard is for administrators only.');
          }
        }
      }
    } catch (error: any) {
      secureConsole.error('Login error:', error);
      setError(error.message || 'Failed to log in');
      toast.error('Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center animated-bg py-12 px-4 sm:px-6 lg:px-8">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-br from-blue-500/10 to-purple-500/10 animate-rotate-slow" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-tl from-indigo-500/10 to-pink-500/10 animate-rotate-slow-reverse" />
        </div>
      </div>

      {/* Login Card */}
      <div className="max-w-md w-full relative z-10">
        <div className="floating-card glass-effect rounded-2xl p-8 shadow-2xl backdrop-blur-xl">
          {/* Logo Section */}
          <div className="text-center space-y-6 mb-8">
            <div className="relative inline-block">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-purple-500/30 rounded-full blur-2xl opacity-75 animate-pulse" />
              <img
                src={logo}
                alt="Logo"
                className="relative mx-auto h-24 w-auto animate-float"
              />
            </div>
            
            <div className="space-y-2">
              <h2 className="text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Welcome Back
              </h2>
              <p className="text-dark-text-secondary text-lg">
                Sign in to your admin account
              </p>
            </div>
          </div>
          
          {/* Form Section */}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {error && (
              <div className="rounded-xl bg-red-500/10 border border-red-500/20 p-4 backdrop-blur-sm animate-fade-in">
                <div className="text-sm text-red-400 flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                  {error}
                </div>
              </div>
            )}
            
            <div className="space-y-5">
              {/* Email Input */}
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 -m-1" />
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="input-style"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </div>
              
              {/* Password Input */}
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 -m-1" />
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="input-style"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl blur opacity-20 group-hover:opacity-40 transition duration-1000 group-hover:duration-200" />
              <button
                type="submit"
                disabled={loading}
                className="relative btn-primary group w-full flex items-center justify-center"
              >
                {loading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <span>Signing in...</span>
                  </div>
                ) : (
                  <span className="flex items-center justify-center">
                    Sign in
                    <svg className="ml-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
