import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { FiSearch, FiFilter, FiCheck, FiX } from 'react-icons/fi';
import { secureConsole } from '../../utils/security';

interface PendingUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: 'pending';
  createdAt: string;
}

const PendingUsers: React.FC = () => {
  const [pendingUsers, setPendingUsers] = useState<PendingUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  const fetchPendingUsers = async () => {
    try {
      const db = getFirestore();
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('status', '==', 'pending'),
        where('role', '!=', 'admin')
      );
      const querySnapshot = await getDocs(q);
      
      const fetchedUsers = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as PendingUser[];

      setPendingUsers(fetchedUsers);
    } catch (error) {
      secureConsole.error('Error fetching pending users:', error);
      toast.error('Failed to fetch pending users');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === filteredUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(filteredUsers.map(user => user.id));
    }
  };

  const handleSelectUser = (userId: string) => {
    setSelectedUsers(prev =>
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleApproveUsers = async () => {
    if (selectedUsers.length === 0) {
      toast.error('Please select users to approve');
      return;
    }

    try {
      setIsLoading(true);
      const db = getFirestore();
      
      await Promise.all(
        selectedUsers.map(async (userId) => {
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, {
            status: 'active',
            updatedAt: new Date().toISOString()
          });
        })
      );

      toast.success('Users approved successfully');
      setSelectedUsers([]);
      await fetchPendingUsers();
    } catch (error) {
      secureConsole.error('Error approving users:', error);
      toast.error('Failed to approve users');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectUsers = async () => {
    if (selectedUsers.length === 0) {
      toast.error('Please select users to reject');
      return;
    }

    try {
      setIsLoading(true);
      const db = getFirestore();
      
      await Promise.all(
        selectedUsers.map(async (userId) => {
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, {
            status: 'inactive',
            updatedAt: new Date().toISOString()
          });
        })
      );

      toast.success('Users rejected successfully');
      setSelectedUsers([]);
      await fetchPendingUsers();
    } catch (error) {
      secureConsole.error('Error rejecting users:', error);
      toast.error('Failed to reject users');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredUsers = pendingUsers.filter(user => {
    const matchesSearch = 
      (user.firstName + ' ' + user.lastName).toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole = filterRole === 'all' || user.role === filterRole;

    return matchesSearch && matchesRole;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Pending Users</h1>
        <div className="flex space-x-4">
          <button
            onClick={handleApproveUsers}
            disabled={selectedUsers.length === 0 || isLoading}
            className="flex items-center px-4 py-2 rounded-lg bg-green-500/20 text-green-500 hover:bg-green-500/30 transition-colors duration-200 disabled:opacity-50"
          >
            <FiCheck className="mr-2" />
            Approve Selected
          </button>
          <button
            onClick={handleRejectUsers}
            disabled={selectedUsers.length === 0 || isLoading}
            className="flex items-center px-4 py-2 rounded-lg bg-red-500/20 text-red-500 hover:bg-red-500/30 transition-colors duration-200 disabled:opacity-50"
          >
            <FiX className="mr-2" />
            Reject Selected
          </button>
        </div>
      </div>

      <div className="bg-dark-secondary/30 rounded-xl p-6">
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="flex-1 relative">
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg bg-dark-secondary/50 border border-white/10 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <div className="relative">
            <FiFilter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
              className="pl-10 pr-4 py-2 rounded-lg bg-dark-secondary/50 border border-white/10 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            >
              <option value="all">All Roles</option>
              <option value="user">User</option>
              <option value="editor">Editor</option>
            </select>
          </div>
        </div>

        {isLoading ? (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-2 text-gray-400">Loading pending users...</p>
          </div>
        ) : filteredUsers.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-gray-400">No pending users found</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-white/10">
                  <th className="py-3 px-4">
                    <input
                      type="checkbox"
                      checked={selectedUsers.length === filteredUsers.length}
                      onChange={handleSelectAll}
                      className="rounded bg-dark-secondary/50 border-white/10 text-blue-500 focus:ring-blue-500"
                    />
                  </th>
                  <th className="text-left py-3 px-4">Name</th>
                  <th className="text-left py-3 px-4">Email</th>
                  <th className="text-left py-3 px-4">Role</th>
                  <th className="text-left py-3 px-4">Requested At</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id} className="border-b border-white/10 hover:bg-white/5">
                    <td className="py-3 px-4">
                      <input
                        type="checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleSelectUser(user.id)}
                        className="rounded bg-dark-secondary/50 border-white/10 text-blue-500 focus:ring-blue-500"
                      />
                    </td>
                    <td className="py-3 px-4">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="py-3 px-4">{user.email}</td>
                    <td className="py-3 px-4">
                      <span className="px-2 py-1 rounded-full text-xs font-medium bg-blue-500/10 text-blue-500">
                        {user.role}
                      </span>
                    </td>
                    <td className="py-3 px-4">
                      {new Date(user.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingUsers;
