import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  updateDoc, 
  serverTimestamp,
  Timestamp,
  deleteField
} from 'firebase/firestore';
import { 
  getAuth,
  updatePassword, 
  EmailAuthProvider, 
  reauthenticateWithCredential 
} from 'firebase/auth';
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL,
  deleteObject,
  listAll,
  uploadBytesResumable
} from 'firebase/storage';
import { toast } from 'react-hot-toast';
import { compressImage } from '../../utils/imageCompression';
import OfficialUseOnly from '../../components/OfficialUseOnly';
import { MemberType, Designation, MemberStatus } from '../../types/memberTypes';
import { FiEdit2, FiTrash2, FiFile, FiRotateCw, FiRotateCcw, FiUpload, FiDownload, FiSave } from 'react-icons/fi';
import { secureConsole, withAdminProtection } from '../../utils/security';

// Types and Interfaces
interface UserData {
  fullName: string;
  email: string;
  phone: string;
  gender: string;
  dateOfBirth: string;
  aadhaarNumber: string;
  bloodGroup: string;
  fatherOrHusbandName: string;
  education: string;
  languagesKnown: string;
  identificationMark: string;
  profession: string;
  postHeld: string;
  currentAddressLine1: string;
  currentAddressLine2: string;
  currentState: string;
  currentDistrict: string;
  currentCityVillage: string;
  currentPinCode: string;
  currentStdCode: string;
  permanentAddressLine1: string;
  permanentAddressLine2: string;
  permanentState: string;
  permanentDistrict: string;
  permanentCityVillage: string;
  permanentPinCode: string;
  permanentStdCode: string;
  sameAsCurrent: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleRegistration: string;
  witness1Name: string;
  witness1Phone: string;
  witness1Aadhaar: string;
  witness2Name: string;
  witness2Phone: string;
  witness2Aadhaar: string;
  validFrom: string;
  validUntil: string;
  memberStatus: MemberStatus;
  memberType: MemberType;
  designation: Designation;
  branch?: string;
  officeState: string;
  officeDistrict: string;
  officeCityVillage: string;
  enrollmentNumber: string;
  range: string;
  role: string;
  userRole: string;
  adminVerified: boolean;
  emailVerified: boolean;
  profileUpdated: boolean;
  verificationStatus: string;
  validity: string;
  createdAt?: Date | Timestamp;
  createdBy?: string;
  updatedAt?: Date | Timestamp;
  lastUpdatedBy: string | null;
  history: any[];
  photo?: string;
  aadhaarFront?: string;
  aadhaarBack?: string;
  promissoryNote?: string;
  authUid?: string;
  [key: string]: any;
}

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
            <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Success!</h3>
          <p className="text-gray-600 text-center mb-6">{message}</p>
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

interface DocumentInfo {
  name: string;
  url: string;
  type: 'storage' | 'collection';
  field?: keyof UserData;
}

const EditUserComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const auth = getAuth();
  const storage = getStorage();
  const db = getFirestore();
  const userId = id!;
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string>('');
  const [userDocuments, setUserDocuments] = useState<DocumentInfo[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, { status: 'idle' | 'uploading' | 'completed' | 'error', progress: number }>>({
    photo: { status: 'idle', progress: 0 },
    aadhaarFront: { status: 'idle', progress: 0 },
    aadhaarBack: { status: 'idle', progress: 0 },
    vehicleRegistration: { status: 'idle', progress: 0 },
    promissoryNote: { status: 'idle', progress: 0 }
  });

  const [userData, setUserData] = useState<UserData>({
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    dateOfBirth: '',
    aadhaarNumber: '',
    bloodGroup: '',
    fatherOrHusbandName: '',
    education: '',
    languagesKnown: '',
    identificationMark: '',
    profession: '',
    postHeld: '',
    currentAddressLine1: '',
    currentAddressLine2: '',
    currentState: '',
    currentDistrict: '',
    currentCityVillage: '',
    currentPinCode: '',
    currentStdCode: '',
    permanentAddressLine1: '',
    permanentAddressLine2: '',
    permanentState: '',
    permanentDistrict: '',
    permanentCityVillage: '',
    permanentPinCode: '',
    permanentStdCode: '',
    sameAsCurrent: false,
    vehicleMake: '',
    vehicleModel: '',
    vehicleRegistration: '',
    witness1Name: '',
    witness1Phone: '',
    witness1Aadhaar: '',
    witness2Name: '',
    witness2Phone: '',
    witness2Aadhaar: '',
    validFrom: '',
    validUntil: '',
    memberStatus: 'Active',
    memberType: 'Member',
    designation: '',
    branch: '',
    officeState: '',
    officeDistrict: '',
    officeCityVillage: '',
    enrollmentNumber: '',
    range: '',
    role: '',
    userRole: '',
    adminVerified: false,
    emailVerified: false,
    profileUpdated: false,
    verificationStatus: '',
    validity: '',
    createdAt: undefined,
    createdBy: '',
    updatedAt: undefined,
    lastUpdatedBy: '',
    history: [],
    photo: '',
    aadhaarFront: '',
    aadhaarBack: '',
    promissoryNote: '',
    authUid: ''
  });

  const [documentUrls, setDocumentUrls] = useState<Record<string, string>>({});

  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
    fieldName: string;
  }>({
    isOpen: false,
    fieldName: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;

      try {
        // Get user data from users collection
        const userRef = doc(db, 'users', id);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          const data = userSnap.data() as UserData;
          secureConsole.log('Fetched user data:', data); // Debug log
          setUserData(data);
          
          // Update document URLs immediately when we get user data
          setDocumentUrls({
            photo: data.photo || '',
            aadhaarFront: data.aadhaarFront || '',
            aadhaarBack: data.aadhaarBack || '',
            vehicleRegistration: data.vehicleRegistration || '',
            promissoryNote: data.promissoryNote || ''
          });
          
          // Also check userDetails collection for any additional URLs
          try {
            const userDetailsRef = doc(db, 'userDetails', id);
            const userDetailsSnap = await getDoc(userDetailsRef);
            if (userDetailsSnap.exists()) {
              const detailsData = userDetailsSnap.data();
              setDocumentUrls(prev => ({
                photo: detailsData.photo || prev.photo || '',
                aadhaarFront: detailsData.aadhaarFront || prev.aadhaarFront || '',
                aadhaarBack: detailsData.aadhaarBack || prev.aadhaarBack || '',
                vehicleRegistration: detailsData.vehicleRegistration || prev.vehicleRegistration || '',
                promissoryNote: detailsData.promissoryNote || prev.promissoryNote || ''
              }));
            }
          } catch (error) {
            secureConsole.error('Error fetching user details:', error);
          }
        }
      } catch (error) {
        secureConsole.error('Error fetching user data:', error);
        toast.error('Failed to fetch user data');
      }
    };

    fetchUserData();
  }, [id]);

  const fetchUserDocuments = async () => {
    if (!id) return;
    
    try {
      // Fetch documents from Firebase Storage
      const storageRef = ref(storage, `users/${id}/documents`);
      const result = await listAll(storageRef);
      
      const storageDocuments = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return {
            name: item.name.split('_').slice(1).join('_'),
            url: url,
            type: 'storage' as const
          };
        })
      );

      // Fetch URLs from userDetails collection
      const userRef = doc(db, 'userDetails', id);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();

      const urlFields: Array<keyof UserData> = ['photo', 'aadhaarFront', 'aadhaarBack', 'vehicleRegistration', 'promissoryNote'];
      const collectionDocuments = urlFields
        .filter(field => userData?.[field])
        .map(field => ({
          name: formatFieldName(field.toString()),
          url: userData![field] as string,
          type: 'collection' as const,
          field: field
        }));

      // Combine both arrays and update state
      setUserDocuments([...storageDocuments, ...collectionDocuments]);
    } catch (error) {
      secureConsole.error('Error fetching documents:', error);
      toast.error('Failed to fetch documents');
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserDocuments();
    }
  }, [id]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;

      try {
        const userRef = doc(db, 'userDetails', id!);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          const data = userSnap.data();
          secureConsole.log('=== Data Fetching Debug Logs ===');
          secureConsole.log('1. Raw Firestore Data:', data);
          
          // Personal Information
          secureConsole.log('2. Personal Information:', {
            fullName: data.fullName,
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            dateOfBirth: data.dateOfBirth,
            aadhaarNumber: data.aadhaarNumber,
            bloodGroup: data.bloodGroup,
            fatherOrHusbandName: data.fatherOrHusbandName
          });

          // Education and Professional Details
          secureConsole.log('3. Education and Professional:', {
            education: data.education,
            languagesKnown: data.languagesKnown,
            identificationMark: data.identificationMark,
            profession: data.profession,
            postHeld: data.postHeld
          });

          // Address Information
          secureConsole.log('4. Current Address:', {
            currentAddressLine1: data.currentAddressLine1,
            currentAddressLine2: data.currentAddressLine2,
            currentState: data.currentState,
            currentDistrict: data.currentDistrict,
            currentCityVillage: data.currentCityVillage,
            currentPinCode: data.currentPinCode,
            currentStdCode: data.currentStdCode
          });

          secureConsole.log('5. Permanent Address:', {
            permanentAddressLine1: data.permanentAddressLine1,
            permanentAddressLine2: data.permanentAddressLine2,
            permanentState: data.permanentState,
            permanentDistrict: data.permanentDistrict,
            permanentCityVillage: data.permanentCityVillage,
            permanentPinCode: data.permanentPinCode,
            permanentStdCode: data.permanentStdCode,
            sameAsCurrent: data.sameAsCurrent
          });

          // Vehicle Information
          secureConsole.log('6. Vehicle Information:', {
            vehicleMake: data.vehicleMake,
            vehicleModel: data.vehicleModel,
            vehicleRegistration: data.vehicleRegistration
          });

          // Witness Information
          secureConsole.log('7. Witness Information:', {
            witness1Name: data.witness1Name,
            witness1Phone: data.witness1Phone,
            witness1Aadhaar: data.witness1Aadhaar,
            witness2Name: data.witness2Name,
            witness2Phone: data.witness2Phone,
            witness2Aadhaar: data.witness2Aadhaar
          });

          // Membership Details
          secureConsole.log('8. Membership Details:', {
            validFrom: data.validFrom,
            validUntil: data.validUntil,
            memberStatus: data.memberStatus
          });

          const formatDate = (date: any) => {
            if (!date) return '';
            if (date instanceof Date) {
              return date.toISOString().split('T')[0];
            }
            // Handle Firestore Timestamp
            if (date && typeof date.toDate === 'function') {
              return date.toDate().toISOString().split('T')[0];
            }
            // Handle string dates
            if (typeof date === 'string') {
              // If it's already in YYYY-MM-DD format, return as is
              if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                return date;
              }
              // Try to parse the date string
              const parsedDate = new Date(date);
              if (!isNaN(parsedDate.getTime())) {
                return parsedDate.toISOString().split('T')[0];
              }
            }
            return '';
          };

          const formattedData: UserData = {
            fullName: data.fullName || '',
            email: data.email || '',
            phone: data.phone || '',
            gender: data.gender || '',
            dateOfBirth: formatDate(data.dateOfBirth),
            aadhaarNumber: data.aadhaarNumber || '',
            bloodGroup: data.bloodGroup || '',
            fatherOrHusbandName: data.fatherOrHusbandName || '',
            education: data.education || '',
            languagesKnown: data.languagesKnown || '',
            identificationMark: data.identificationMark || '',
            profession: data.profession || '',
            postHeld: data.postHeld || '',
            currentAddressLine1: data.currentAddressLine1 || '',
            currentAddressLine2: data.currentAddressLine2 || '',
            currentState: data.currentState || '',
            currentDistrict: data.currentDistrict || '',
            currentCityVillage: data.currentCityVillage || '',
            currentPinCode: data.currentPinCode || '',
            currentStdCode: data.currentStdCode || '',
            permanentAddressLine1: data.permanentAddressLine1 || '',
            permanentAddressLine2: data.permanentAddressLine2 || '',
            permanentState: data.permanentState || '',
            permanentDistrict: data.permanentDistrict || '',
            permanentCityVillage: data.permanentCityVillage || '',
            permanentPinCode: data.permanentPinCode || '',
            permanentStdCode: data.permanentStdCode || '',
            sameAsCurrent: data.sameAsCurrent || false,
            vehicleMake: data.vehicleMake || '',
            vehicleModel: data.vehicleModel || '',
            vehicleRegistration: data.vehicleRegistration || '',
            witness1Name: data.witness1Name || '',
            witness1Phone: data.witness1Phone || '',
            witness1Aadhaar: data.witness1Aadhaar || '',
            witness2Name: data.witness2Name || '',
            witness2Phone: data.witness2Phone || '',
            witness2Aadhaar: data.witness2Aadhaar || '',
            validFrom: formatDate(data.validFrom),
            validUntil: formatDate(data.validUntil),
            memberStatus: data.memberStatus || 'Active',
            memberType: data.memberType || 'Member',
            designation: data.designation || '',
            branch: data.branch || '',
            officeState: data.officeState || '',
            officeDistrict: data.officeDistrict || '',
            officeCityVillage: data.officeCityVillage || '',
            enrollmentNumber: data.enrollmentNumber || '',
            range: data.range || '',
            role: data.role || '',
            userRole: data.userRole || '',
            adminVerified: data.adminVerified || false,
            emailVerified: data.emailVerified || false,
            profileUpdated: data.profileUpdated || false,
            verificationStatus: data.verificationStatus || '',
            validity: data.validity || '',
            createdAt: data.createdAt ? 
              (data.createdAt.toDate ? data.createdAt.toDate() : new Date(data.createdAt)) 
              : undefined,
            createdBy: data.createdBy || '',
            updatedAt: data.updatedAt ? 
              (data.updatedAt.toDate ? data.updatedAt.toDate() : new Date(data.updatedAt)) 
              : undefined,
            lastUpdatedBy: data.lastUpdatedBy || null,
            history: data.history || [],
            photo: data.photo || '',
            aadhaarFront: data.aadhaarFront || '',
            aadhaarBack: data.aadhaarBack || '',
            promissoryNote: data.promissoryNote || '',
            authUid: data.authUid || ''
          };

          secureConsole.log('=== Formatted Data Debug Logs ===');
          secureConsole.log('9. Formatted Personal Info:', {
            fullName: formattedData.fullName,
            email: formattedData.email,
            phone: formattedData.phone,
            gender: formattedData.gender,
            dateOfBirth: formattedData.dateOfBirth,
            aadhaarNumber: formattedData.aadhaarNumber,
            bloodGroup: formattedData.bloodGroup,
            fatherOrHusbandName: formattedData.fatherOrHusbandName
          });

          secureConsole.log('10. Formatted Education:', {
            education: formattedData.education,
            languagesKnown: formattedData.languagesKnown,
            identificationMark: formattedData.identificationMark,
            profession: formattedData.profession,
            postHeld: formattedData.postHeld
          });

          secureConsole.log('11. Formatted Addresses:', {
            current: {
              line1: formattedData.currentAddressLine1,
              line2: formattedData.currentAddressLine2,
              state: formattedData.currentState,
              district: formattedData.currentDistrict,
              cityVillage: formattedData.currentCityVillage,
              pincode: formattedData.currentPinCode,
              stdCode: formattedData.currentStdCode
            },
            permanent: {
              line1: formattedData.permanentAddressLine1,
              line2: formattedData.permanentAddressLine2,
              state: formattedData.permanentState,
              district: formattedData.permanentDistrict,
              cityVillage: formattedData.permanentCityVillage,
              pincode: formattedData.permanentPinCode,
              stdCode: formattedData.permanentStdCode
            },
            sameAsCurrent: formattedData.sameAsCurrent
          });

          secureConsole.log('12. Formatted Vehicle Info:', {
            make: formattedData.vehicleMake,
            model: formattedData.vehicleModel,
            regNumber: formattedData.vehicleRegistration
          });

          secureConsole.log('13. Formatted Witness Info:', {
            witness1: {
              name: formattedData.witness1Name,
              phone: formattedData.witness1Phone,
              aadhaar: formattedData.witness1Aadhaar
            },
            witness2: {
              name: formattedData.witness2Name,
              phone: formattedData.witness2Phone,
              aadhaar: formattedData.witness2Aadhaar
            }
          });

          secureConsole.log('14. Formatted Membership:', {
            validFrom: formattedData.validFrom,
            validUntil: formattedData.validUntil,
            memberStatus: formattedData.memberStatus
          });

          secureConsole.log('15. Official Use Only Fields:', {
            memberType: formattedData.memberType,
            designation: formattedData.designation,
            branch: formattedData.branch,
            officeState: formattedData.officeState,
            officeDistrict: formattedData.officeDistrict,
            officeCityVillage: formattedData.officeCityVillage,
            enrollmentNumber: formattedData.enrollmentNumber,
            range: formattedData.range,
            role: formattedData.role,
            userRole: formattedData.userRole,
            adminVerified: formattedData.adminVerified,
            emailVerified: formattedData.emailVerified,
            profileUpdated: formattedData.profileUpdated,
            verificationStatus: formattedData.verificationStatus,
            validity: formattedData.validity
          });

          secureConsole.log('Formatted data:', formattedData);
          setUserData(formattedData);
          setLoading(false);
        } else {
          setError('User not found');
          toast.error('User not found');
        }
      } catch (error) {
        secureConsole.error('Error fetching user data:', error);
        setError('Failed to fetch user data');
        toast.error('Failed to fetch user data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Handle nested objects
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setUserData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent as keyof UserData],
          [child]: value
        }
      }));
    } else {
      setUserData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      // Get the validFrom date, default to today if not set
      const startDate = userData.validFrom ? new Date(userData.validFrom) : new Date();
      
      // Calculate validUntil as one year from validFrom if not set
      let endDate: Date;
      if (userData.validUntil) {
        endDate = new Date(userData.validUntil);
        // Validate that end date is after start date
        if (endDate <= startDate) {
          toast.error('Validity end date must be after start date');
          setIsSaving(false);
          return;
        }
      } else {
        // Default to one year from start date
        endDate = new Date(startDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
      }

      const hasValueChanged = (oldValue: any, newValue: any): boolean => {
        // Handle undefined/null cases
        if (!oldValue && !newValue) return false;
        if (!oldValue || !newValue) return true;

        // Handle Timestamp comparisons
        if (oldValue?.toDate && typeof oldValue.toDate === 'function') {
          const oldDate = oldValue.toDate();
          const newDate = newValue instanceof Date ? newValue : new Date(newValue);
          return oldDate.getTime() !== newDate.getTime();
        }

        // Handle Date comparisons
        if (oldValue instanceof Date || newValue instanceof Date) {
          const oldDate = oldValue instanceof Date ? oldValue : new Date(oldValue);
          const newDate = newValue instanceof Date ? newValue : new Date(newValue);
          return oldDate.getTime() !== newDate.getTime();
        }

        // Handle object comparisons
        if (typeof oldValue === 'object' && typeof newValue === 'object') {
          return JSON.stringify(oldValue) !== JSON.stringify(newValue);
        }

        // Handle primitive value comparisons
        return oldValue !== newValue;
      };

      const updatedUserData: UserData = {
        ...userData,
        validFrom: startDate.toISOString().split('T')[0],
        validUntil: endDate.toISOString().split('T')[0],
        updatedAt: Timestamp.fromDate(new Date()),
      };

      const userRef = doc(db, 'userDetails', id!);
      const userSnap = await getDoc(userRef);
      
      if (!userSnap.exists()) {
        toast.error('User not found');
        setIsSaving(false);
        return;
      }

      const currentData = userSnap.data();
      
      // Ensure authUid is preserved
      if (currentData.authUid) {
        updatedUserData.authUid = currentData.authUid;
      }

      const updates: Record<string, any> = {
        updatedAt: Timestamp.fromDate(new Date()),
        lastUpdatedBy: auth.currentUser?.email || null,
        authUid: currentData.authUid // Preserve authUid in updates
      };

      // Compare each field and track changes
      (Object.keys(updatedUserData) as Array<keyof UserData>).forEach((key) => {
        if (hasValueChanged(currentData[key], updatedUserData[key])) {
          updates[key] = updatedUserData[key];
        }
      });

      // If there are changes, update the document
      if (Object.keys(updates).length > 0) {
        // Update the document
        await updateDoc(userRef, updates);

        secureConsole.log('User updated successfully');
        toast.success('User updated successfully');
        navigate('/admin/users');
      } else {
        toast('No changes to save', {
          style: {
            background: '#3498db',
            color: '#fff'
          }
        });
      }
    } catch (error) {
      secureConsole.error('Error updating user:', error);
      toast.error('Error updating user');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate('/admin/users');  // Updated path to match UsersList route
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !id) return;

    try {
      setUploadStatus('Uploading...');
      
      // Create a storage reference
      const timestamp = Date.now();
      const fileName = `${timestamp}_${file.name}`;
      const storageRef = ref(storage, `users/${id}/documents/${fileName}`);
      
      // Upload file
      await uploadBytes(storageRef, file);
      
      // Get download URL
      const downloadUrl = await getDownloadURL(storageRef);
      setUploadedFileUrl(downloadUrl);
      
      // Refresh documents list
      await fetchUserDocuments();
      
      setUploadStatus('Upload complete');
      toast.success('File uploaded successfully');
      
      // Clear the input
      event.target.value = '';
    } catch (error) {
      secureConsole.error('Error uploading file:', error);
      setUploadStatus('Upload failed');
      toast.error('Failed to upload file');
    }
  };

  const handleDeleteFile = async () => {
    if (!uploadedFileUrl) return;

    try {
      const fileRef = ref(storage, uploadedFileUrl);
      await deleteObject(fileRef);
      setUploadedFileUrl('');
      setUploadStatus('');
      toast.success('File deleted successfully');
    } catch (error) {
      secureConsole.error('Error deleting file:', error);
      toast.error('Failed to delete file');
    }
  };

  const handleDeleteDocument = async (document: DocumentInfo) => {
    try {
      if (document.type === 'storage') {
        // Delete from Firebase Storage
        const fileRef = ref(storage, document.url);
        await deleteObject(fileRef);
      } else if (document.field) {
        // Delete from userDetails collection
        const userRef = doc(db, 'userDetails', id!);
        const fieldName = document.field.toString();
        await updateDoc(userRef, {
          [fieldName]: deleteField()
        });
        
        // Update local state
        setUserData(prev => ({
          ...prev,
          [fieldName]: ''
        }));
      }
      
      await fetchUserDocuments();
      toast.success('Document deleted successfully');
    } catch (error) {
      secureConsole.error('Error deleting document:', error);
      toast.error('Failed to delete document');
    }
  };

  const handleSpecificFileUpload = async (file: File, fieldName: string) => {
    if (!file || !id) return;

    try {
      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'uploading', progress: 0 }
      }));

      // Create a storage reference
      const storageRef = ref(storage, `users/${id}/${fieldName}`);
      
      // Upload file with progress monitoring
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'uploading', progress }
          }));
        },
        (error) => {
          secureConsole.error('Upload error:', error);
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'error', progress: 0 }
          }));
          toast.error('Failed to upload file');
        },
        async () => {
          try {
            // Get download URL
            const downloadUrl = await getDownloadURL(storageRef);
            secureConsole.log('File uploaded, URL:', downloadUrl);
            
            // Update userDetails collection
            const userDetailsRef = doc(db, 'userDetails', id);
            await updateDoc(userDetailsRef, {
              [fieldName]: downloadUrl
            });
            
            // Update local states
            setUserData(prev => ({
              ...prev,
              [fieldName]: downloadUrl
            }));
            
            setUploadProgress(prev => ({
              ...prev,
              [fieldName]: { status: 'completed', progress: 100 }
            }));
            
            toast.success('File uploaded successfully');
          } catch (error) {
            secureConsole.error('Error completing upload:', error);
            toast.error('Failed to complete upload');
          }
        }
      );
    } catch (error) {
      secureConsole.error('Error handling file upload:', error);
      toast.error('Failed to handle file upload');
    }
  };

  const handleRemoveFile = async (fieldName: string) => {
    if (!id) return;

    try {
      // Get the current URL
      const currentUrl = userData[fieldName];
      if (currentUrl) {
        try {
          // Delete from Storage if URL exists
          const fileRef = ref(storage, currentUrl);
          await deleteObject(fileRef);
        } catch (error) {
          secureConsole.error('Error deleting file from storage:', error);
        }
      }
      
      // Update userDetails collection
      const userDetailsRef = doc(db, 'userDetails', id);
      await updateDoc(userDetailsRef, {
        [fieldName]: ''
      });
      
      // Update local states
      setUserData(prev => ({
        ...prev,
        [fieldName]: ''
      }));
      
      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'idle', progress: 0 }
      }));
      
      toast.success('File removed successfully');
    } catch (error) {
      secureConsole.error('Error removing file:', error);
      toast.error('Failed to remove file');
    }
  };

  const handleDeleteConfirm = async () => {
    const fieldName = deleteConfirmation.fieldName;
    setDeleteConfirmation({ isOpen: false, fieldName: '' });
    await handleRemoveFile(fieldName);
  };

  // Helper function to format field names
  const formatFieldName = (field: string): string => {
    return field
      // Add space before capital letters
      .replace(/([A-Z])/g, ' $1')
      // Capitalize first letter
      .replace(/^./, str => str.toUpperCase())
      .trim();
  };

  // Download handler function
  const handleDownload = async (event: any, url: string, documentType: string) => {
    event.preventDefault();

    try {
      // Fetch the file
      const response = await fetch(url);
      const blob = await response.blob();
      
      // Create a temporary link to trigger download
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      
      // Set filename based on document type
      const fileExtension = url.split('?')[0].split('.').pop() || 'pdf';
      link.download = `${documentType}.${fileExtension}`;
      
      // Trigger download
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }, 100);
    } catch (error) {
      secureConsole.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  // Style constants for consistent UI
  const inputClassName = `
    w-full px-4 py-3 
    bg-slate-800/40 
    border border-slate-700/50 
    rounded-lg 
    focus:ring-2 focus:ring-emerald-500/50 focus:border-emerald-500/50 
    transition-all duration-200
    placeholder:text-slate-500
    text-slate-300
  `;

  const labelClassName = `
    block text-sm font-medium 
    text-slate-400 
    mb-1.5
  `;

  const sectionClassName = `
    bg-slate-900/50 
    backdrop-blur-sm 
    rounded-xl 
    border border-slate-800/50 
    p-6 md:p-8 
    space-y-6
    shadow-lg 
    shadow-slate-900/20
    transition-all duration-300 
    hover:shadow-slate-900/30
  `;

  const sectionHeaderClassName = `
    text-xl font-semibold 
    text-emerald-400
    mb-6 
    flex items-center gap-2
    border-b border-slate-800/50 
    pb-4
  `;

  const gridClassName = `
    grid grid-cols-1 
    md:grid-cols-2 
    lg:grid-cols-4 
    gap-6
  `;

  const bloodGroupOptions = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
  const genderOptions = ['male', 'female', 'other'];

  const handleSameAsCurrentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setUserData(prev => ({
      ...prev,
      sameAsCurrent: checked,
      permanentAddressLine1: checked ? prev.currentAddressLine1 : '',
      permanentAddressLine2: checked ? prev.currentAddressLine2 : '',
      permanentState: checked ? prev.currentState : '',
      permanentDistrict: checked ? prev.currentDistrict : '',
      permanentCityVillage: checked ? prev.currentCityVillage : '',
      permanentPinCode: checked ? prev.currentPinCode : '',
      permanentStdCode: checked ? prev.currentStdCode : ''
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300">
      {/* Success Modal */}
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={handleSuccessModalClose}
        message="User data has been updated successfully!"
      />

      {/* Delete Confirmation Dialog */}
      {deleteConfirmation.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-slate-800 rounded-lg p-6 max-w-sm w-full mx-4">
            <h3 className="text-lg font-medium text-slate-200 mb-4">Confirm Delete</h3>
            <p className="text-slate-300 mb-6">
              Are you sure you want to delete this document? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setDeleteConfirmation({ isOpen: false, fieldName: '' })}
                className="px-4 py-2 bg-slate-600 hover:bg-slate-700 text-white rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteConfirm}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Edit User</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Personal Details */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Personal Details
            </h2>
            <div className={gridClassName}>
              {/* Full Name */}
              <div>
                <label className={labelClassName}>
                  Full Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={userData.fullName}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Email */}
              <div>
                <label className={labelClassName}>
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Phone Number */}
              <div>
                <label className={labelClassName}>
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{10}"
                  title="Please enter a valid 10-digit phone number"
                  required
                />
              </div>

              {/* Gender */}
              <div>
                <label className={labelClassName}>
                  Gender <span className="text-red-500">*</span>
                </label>
                <select
                  name="gender"
                  value={userData.gender}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="">Select Gender</option>
                  {genderOptions.map((option) => (
                    <option key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              {/* Date of Birth */}
              <div>
                <label className={labelClassName}>
                  Date of Birth <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>

              {/* Aadhaar Number */}
              <div>
                <label className={labelClassName}>
                  Aadhaar Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="aadhaarNumber"
                  value={userData.aadhaarNumber}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{12}"
                  title="Please enter a valid 12-digit Aadhaar number"
                  required
                  maxLength={12}
                />
              </div>

              {/* Blood Group */}
              <div>
                <label className={labelClassName}>
                  Blood Group <span className="text-red-500">*</span>
                </label>
                <select
                  name="bloodGroup"
                  value={userData.bloodGroup}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="">Select Blood Group</option>
                  {bloodGroupOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              {/* Father/Husband Name */}
              <div>
                <label className={labelClassName}>
                  Father/Husband Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="fatherOrHusbandName"
                  value={userData.fatherOrHusbandName}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Education */}
              <div>
                <label className={labelClassName}>
                  Education <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="education"
                  value={userData.education}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Languages Known */}
              <div>
                <label className={labelClassName}>
                  Languages Known <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="languagesKnown"
                  value={userData.languagesKnown}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  placeholder="e.g., English, Hindi"
                />
              </div>

              {/* Identification Mark */}
              <div>
                <label className={labelClassName}>
                  Identification Mark <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="identificationMark"
                  value={userData.identificationMark}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Profession */}
              <div>
                <label className={labelClassName}>
                  Profession <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="profession"
                  value={userData.profession}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Post Held */}
              <div>
                <label className={labelClassName}>
                  Post Held <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="postHeld"
                  value={userData.postHeld}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>
            </div>
          </div>

          {/* Current Address */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Current Address
            </h2>
            <div className={gridClassName}>
              {/* Address Line 1 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 1 <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentAddressLine1"
                  value={userData.currentAddressLine1}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Address Line 2 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 2
                </label>
                <input
                  type="text"
                  name="currentAddressLine2"
                  value={userData.currentAddressLine2}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>

              {/* State */}
              <div>
                <label className={labelClassName}>
                  State <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentState"
                  value={userData.currentState}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* District */}
              <div>
                <label className={labelClassName}>
                  District <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentDistrict"
                  value={userData.currentDistrict}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* City/Village */}
              <div>
                <label className={labelClassName}>
                  City/Village <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentCityVillage"
                  value={userData.currentCityVillage}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Pin Code */}
              <div>
                <label className={labelClassName}>
                  Pin Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentPinCode"
                  value={userData.currentPinCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{6}"
                  maxLength={6}
                  title="Please enter a valid 6-digit pin code"
                  required
                />
              </div>

              {/* STD Code */}
              <div>
                <label className={labelClassName}>
                  STD Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentStdCode"
                  value={userData.currentStdCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>
            </div>
          </div>

          {/* Permanent Address */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Permanent Address
            </h2>

            {/* Same as Current Address Checkbox */}
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="sameAsCurrent"
                  checked={userData.sameAsCurrent}
                  onChange={handleSameAsCurrentChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-slate-300">Same as Current Address</span>
              </label>
            </div>
            
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ${userData.sameAsCurrent ? 'opacity-50' : ''}`}>
              {/* Address Line 1 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 1 <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentAddressLine1"
                  value={userData.permanentAddressLine1}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* Address Line 2 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 2
                </label>
                <input
                  type="text"
                  name="permanentAddressLine2"
                  value={userData.permanentAddressLine2}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* State */}
              <div>
                <label className={labelClassName}>
                  State <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentState"
                  value={userData.permanentState}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* District */}
              <div>
                <label className={labelClassName}>
                  District <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentDistrict"
                  value={userData.permanentDistrict}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* City/Village */}
              <div>
                <label className={labelClassName}>
                  City/Village <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentCityVillage"
                  value={userData.permanentCityVillage}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* Pin Code */}
              <div>
                <label className={labelClassName}>
                  Pin Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentPinCode"
                  value={userData.permanentPinCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{6}"
                  maxLength={6}
                  title="Please enter a valid 6-digit pin code"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* STD Code */}
              <div>
                <label className={labelClassName}>
                  STD Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentStdCode"
                  value={userData.permanentStdCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
            </div>
          </div>

          {/* Membership Validity */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 012-2H5a2 2 0 012 2v12a2 2 0 002 2z" />
              </svg>
              Membership Validity
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClassName}>Valid From</label>
                <input
                  type="date"
                  name="validFrom"
                  value={userData.validFrom}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                <p className="mt-1 text-sm text-gray-400">
                  If not specified, today's date will be used
                </p>
              </div>
              <div>
                <label className={labelClassName}>Valid Until</label>
                <input
                  type="date"
                  name="validUntil"
                  value={userData.validUntil}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                <p className="mt-1 text-sm text-gray-400">
                  If not specified, validity will be set to 1 year from start date
                </p>
              </div>
            </div>
          </div>

          {/* Vehicle Details */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Vehicle Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Make */}
              <div>
                <label className={labelClassName}>
                  Make
                </label>
                <input
                  type="text"
                  name="vehicleMake"
                  value={userData.vehicleMake}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="e.g., Toyota, Honda, etc."
                />
              </div>

              {/* Model */}
              <div>
                <label className={labelClassName}>
                  Model
                </label>
                <input
                  type="text"
                  name="vehicleModel"
                  value={userData.vehicleModel}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="e.g., Camry, Civic, etc."
                />
              </div>

              {/* Registration Number */}
              <div>
                <label className={labelClassName}>
                  Registration Number
                </label>
                <input
                  type="text"
                  name="vehicleRegistration"
                  value={userData.vehicleRegistration}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="e.g., KA-01-AB-1234"
                />
              </div>
            </div>
          </div>

          {/* Witness Details */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Witness Details
            </h2>
            <div className="space-y-6">
              {/* Witness 1 */}
              <div>
                <h3 className="text-lg font-medium mb-4">Witness 1</h3>
                <div className={gridClassName}>
                  {/* Full Name */}
                  <div>
                    <label className={labelClassName}>
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness1Name"
                      value={userData.witness1Name}
                      onChange={handleInputChange}
                      required
                      className={inputClassName}
                      placeholder="Enter witness name"
                    />
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className={labelClassName}>
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="witness1Phone"
                      value={userData.witness1Phone}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        setUserData(prev => ({
                          ...prev,
                          witness1Phone: value
                        }));
                      }}
                      pattern="[0-9]{10}"
                      required
                      className={inputClassName}
                      placeholder="Enter 10-digit phone number"
                    />
                    {userData.witness1Phone && userData.witness1Phone.length !== 10 && (
                      <p className="mt-1 text-sm text-red-500">
                        Phone number must be exactly 10 digits
                      </p>
                    )}
                  </div>

                  {/* Aadhaar Number */}
                  <div>
                    <label className={labelClassName}>
                      Aadhaar Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness1Aadhaar"
                      value={userData.witness1Aadhaar}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 12);
                        setUserData(prev => ({
                          ...prev,
                          witness1Aadhaar: value
                        }));
                      }}
                      pattern="[0-9]{12}"
                      required
                      className={inputClassName}
                      placeholder="Enter 12-digit Aadhaar number"
                    />
                    {userData.witness1Aadhaar && userData.witness1Aadhaar.length !== 12 && (
                      <p className="mt-1 text-sm text-red-500">
                        Aadhaar number must be exactly 12 digits
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Witness 2 */}
              <div>
                <h3 className="text-lg font-medium mb-4">Witness 2</h3>
                <div className={gridClassName}>
                  {/* Full Name */}
                  <div>
                    <label className={labelClassName}>
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness2Name"
                      value={userData.witness2Name}
                      onChange={handleInputChange}
                      required
                      className={inputClassName}
                      placeholder="Enter witness name"
                    />
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className={labelClassName}>
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="witness2Phone"
                      value={userData.witness2Phone}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        setUserData(prev => ({
                          ...prev,
                          witness2Phone: value
                        }));
                      }}
                      pattern="[0-9]{10}"
                      required
                      className={inputClassName}
                      placeholder="Enter 10-digit phone number"
                    />
                    {userData.witness2Phone && userData.witness2Phone.length !== 10 && (
                      <p className="mt-1 text-sm text-red-500">
                        Phone number must be exactly 10 digits
                      </p>
                    )}
                  </div>

                  {/* Aadhaar Number */}
                  <div>
                    <label className={labelClassName}>
                      Aadhaar Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness2Aadhaar"
                      value={userData.witness2Aadhaar}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 12);
                        setUserData(prev => ({
                          ...prev,
                          witness2Aadhaar: value
                        }));
                      }}
                      pattern="[0-9]{12}"
                      required
                      className={inputClassName}
                      placeholder="Enter 12-digit Aadhaar number"
                    />
                    {userData.witness2Aadhaar && userData.witness2Aadhaar.length !== 12 && (
                      <p className="mt-1 text-sm text-red-500">
                        Aadhaar number must be exactly 12 digits
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Document Upload Section */}
          <div className={sectionClassName}>
            <h3 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              Required Documents
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                { id: 'photo', label: 'Upload Photo', accept: 'image/*' },
                { id: 'aadhaarFront', label: 'Aadhaar Card (Front)', accept: 'image/*' },
                { id: 'aadhaarBack', label: 'Aadhaar Card (Back)', accept: 'image/*' },
                { id: 'vehicleRegistration', label: 'Vehicle Registration', accept: '.pdf,image/*' },
                { id: 'promissoryNote', label: 'Promissory Note', accept: '.pdf,image/*' }
              ].map(doc => (
                <div key={doc.id} className="space-y-2">
                  <label className="block text-sm font-medium text-slate-300">
                    {doc.label}
                    {userData && userData[doc.id] && (
                      <span className="ml-2 text-xs text-emerald-500">(Document Uploaded)</span>
                    )}
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="file"
                      accept={doc.accept}
                      onChange={(e) => handleSpecificFileUpload(e.target.files?.[0]!, doc.id)}
                      className="hidden"
                      id={`${doc.id}-upload`}
                    />
                    <label
                      htmlFor={`${doc.id}-upload`}
                      className="px-3 py-1 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg flex items-center gap-1 cursor-pointer transition-colors duration-200"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0L8 8m4-4v12" />
                      </svg>
                      Upload
                    </label>

                    {userData && userData[doc.id] && (
                      <>
                        {/* View Button */}
                        <a
                          href={userData[doc.id]}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center gap-1 transition-colors duration-200"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          View
                        </a>

                        {/* Download Button */}
                        <button
                          onClick={(e) => handleDownload(e, userData[doc.id], doc.label)}
                          className="px-3 py-1 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg flex items-center gap-1 transition-colors duration-200"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0L8 8m4-4v12" />
                          </svg>
                          Download
                        </button>

                        {/* Delete Button */}
                        <button
                          type="button"
                          onClick={() => setDeleteConfirmation({ isOpen: true, fieldName: doc.id })}
                          className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white rounded-lg flex items-center gap-1 transition-colors duration-200"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                          Delete
                        </button>
                      </>
                    )}
                  </div>

                  {uploadProgress[doc.id].status === 'uploading' && (
                    <div className="mt-2 h-2 bg-slate-700 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-emerald-500 transition-all duration-300"
                        style={{ width: `${uploadProgress[doc.id].progress}%` }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Official Use Only */}
          <OfficialUseOnly
            userData={{
              memberType: userData.memberType,
              memberStatus: userData.memberStatus,
              designation: userData.designation,
              branch: userData.branch || '',
              officeState: userData.officeState,
              officeDistrict: userData.officeDistrict,
              officeCityVillage: userData.officeCityVillage,
              enrollmentNumber: userData.enrollmentNumber,
              range: userData.range,
              validFrom: userData.validFrom,
              validUntil: userData.validUntil
            }}
            onInputChange={handleInputChange}
            onEnrollmentNumberGenerated={(enrollmentNumber: string) => 
              setUserData(prev => ({ ...prev, enrollmentNumber }))
            }
          />

          <div className="flex justify-end space-x-4 mt-8">
            <button
              type="button"
              onClick={() => navigate('/admin/users')}
              className="px-6 py-2.5 rounded-lg font-medium border border-gray-300 text-gray-300 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className="px-6 py-2.5 rounded-lg font-medium bg-blue-500 text-white hover:bg-blue-600 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const EditUser = withAdminProtection(EditUserComponent);
export default EditUser;
